.myDownloadsContainer {
    padding-left: 70px;
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
    padding: 0px 50px 50px 50px;
    height: 100%;
}

.resize {
    height: 100%;
}

.zoomFile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
}

.outerVideo {
    height: 139px;
    width: 101px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

.innerVideo {
    text-align: center;
}

.pageButton {
    text-align: right;
}

.spinnerContainer {
    position: absolute;
    width: 100%;
    top: 150px;
}

.filterContainer {
    position: absolute;
    right: 0px;
    width: 25%;
    top: 205px;
    height: auto;
    display: block;
}

.filterButton {
    display: none;
    margin-left: 10px;
}

.filterCloseButton {
    float: right;
    margin-top: 15px;
    display: none;
}

.urlPreviewLoadingIndicatorContainer {
    padding-top: 200px;
    text-align: center;
}

.searchResultCard {
    width: 100%;
    max-width: calc(100% - 50px);
}

@media (min-width: 1650px) {
    .searchResultCard {
        max-width: 500px;
    }
}

.searchResultContent {
    word-break: break-word;
}

@media (max-width: 1100px) {
    .hideFilterPanelInSmall {
        display: none;
    }

    .filterButton {
        display: inline-block;
    }

    .filterCloseButton {
        display: inline-block;
    }

    .filterContainer {
        left: 0px;
        width: auto;
    }
}