.headerContainer img {
    max-width: 25em;
    margin-left: 68px;
    margin-bottom: 2em;
}

.redhead {
    width: 100vw;
    height: 100px;
    background-color: #cd1717;
    transform: rotate(-2deg);
    margin-top: -75px;
    margin-left: -2vw;
    margin-bottom: 40px;
    z-index: 999;
}