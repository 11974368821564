.registerContainer {
    margin-left: 70px;
    margin-right: 70px;
}

.captchaContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.registerButtonSpinner {
    margin-right: 5px;
}

.redirectToLoginLink {
    text-decoration: none;
}

.redirectToLoginLink:hover {
    text-decoration: underline;
}