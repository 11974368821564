.mainNavBar {
    width: 100%;
}

.menu {
    float: left;
    margin-left: 10px;
    margin-top: 7px;
}

.signOutErrorBar {
    margin-top: 10px;
    width: calc(100% - 140px);
    padding-left: 70px;
}