.filterPanelLoadingSpinnerContainer {
    margin-top: 50px;
}

.filterPanelErrorContainer {
    margin-top: 50px;
    text-align: center;
}

.loadingSpinner {
    display: inline-block;
    margin-left: 12px;
}