.fullscreenLoadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.fullscreenLoadingSpinnerContainer {
    display: inline-block;
    position: absolute;
    top: calc(50% - 14px);
    left: calc(50% - 14px);
}