.mySubscriptionsContainer {
    margin-left: 70px;
    margin-right: 70px;
}

.mySubscriptionsButtonSpinner {
    margin-right: 5px;
}

.reddot::before {
    color: red;
    /* or whatever color you prefer */
}