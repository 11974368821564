.confirmMailContainer {
    margin-left: 70px;
    margin-right: 70px;
}

.redirectLink {
    text-decoration: none;
}

.redirectLink:hover {
    text-decoration: underline;
}