.bodyContent {
    min-height: 100vh;
}

.ms-Stack-inner {
    min-height: 100vh;
}

#root {
    min-height: 100vh;
}

.tooManyLoginsBanner {
    padding-left: 65px;
    padding-right: 65px;
    width: calc(100% - 130px);
}