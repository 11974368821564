.footerContainer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-top: 16px;
}

.buttonMargin {
    margin-right: 8px;
}

.saveButtonSpinner {
    margin-right: 5px;
}