.loginContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #FFF;
    z-index: 888;
}

.height100 {
    height: 100%;
}

.cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('/img/Themen.jpg');
}

.login {
    padding: 50px;
}

.loginButtonSpinner {
    margin-right: 5px;
}

.redhead {
    width: 100vw;
    height: 100px;
    background-color: #cd1717;
    transform: rotate(-2deg);
    margin-top: -75px;
    margin-left: -2vw;
    z-index: 999;
    position: absolute;
}

.right {
    text-align: right;
}

.logo {
    margin-top: 20px;
}

.linkContainer {
    margin-top: 50px;
}

.redirectLink {
    text-decoration: none;
}

.redirectLink:hover {
    text-decoration: underline;
}