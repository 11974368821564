.mainFooterContainer {
    padding-left: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 60px;
    margin-top: 30px;
}

.mainFooterContainer img {
    height: 30px;
}

.links {
    padding-left: 40px;
}

.linkEntry {
    text-decoration: none;
}

.linkEntry:hover {
    text-decoration: underline;
}