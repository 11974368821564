.mainHomeContainer {
    margin-left: 70px;
    margin-right: 70px;
}

.center {
    text-align: center;
}

.thumbnail {
    width: 204px;
}