.termsOfUseContainer {
    margin-left: 70px;
    margin-right: 70px;
}

.termsOfUseList {
    list-style-type: none;
    padding-left: 10px;
    margin-top: 2px;
}

.termsOfUseList > li:before {
    content: "-";
    text-indent: -15px;
}